import request from '@/utils/request'


// 查询优惠券列表
export function listAirport(query) {
  return request({
    url: '/user/airport-pens/list',
    method: 'get',
    params: query
  })
}

// 查询优惠券分页
export function pageAirport(query) {
  return request({
    url: '/user/airport-pens/page',
    method: 'get',
    params: query
  })
}

// 查询优惠券详细
export function getAirport(data) {
  return request({
    url: '/user/airport-pens/detail',
    method: 'get',
    params: data
  })
}

// 新增优惠券
export function addAirport(data) {
  return request({
    url: '/user/airport-pens/add',
    method: 'post',
    data: data
  })
}

// 修改优惠券
export function updateAirport(data) {
  return request({
    url: '/user/airport-pens/edit',
    method: 'post',
    data: data
  })
}

// 删除优惠券
export function delAirport(data) {
  return request({
    url: '/user/airport-pens/delete',
    method: 'post',
    data: data
  })
}
export function changeIsOpen(data) {
  return request({
    url: '/user/airport-pens/changeIsOpen',
    method: 'post',
    data: data
  })
}