<template>
  <a-drawer width="55%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>选择位置</b>
    </a-divider>
  <div class="map">
    <a-row
      style="display: flex; justify-content: center; padding-bottom: 7vh"
      class=""
    >
      <a-col :xs="24" :xl="24">
        <div class="search-box">
<!--          <h2 style="margin-bottom: 10px">-->
<!--            {{ $t("googlemap.searchAndAdd") }}-->
<!--          </h2>-->
          <GmapAutocomplete @place_changed="setPlace" placeholder='搜索地址' />
          <button class="add-btn cursor" @click="addMarker">
            搜索
          </button>
        </div>
        <br />
        <div class="GmapMapBox">
          <GmapMap
            @click="clickMap"
            :center="center"
            :zoom="zoom"
            :options="mapOptions"
            style="width: 100%; height: 700px"
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              @click="clickMarker(m)"
            />
          </GmapMap>
        </div>

<!--        <a-row style="" class="btn-box">-->
<!--          <a-button type="primary" @click="submitMap">确定</a-button>-->
<!--          <a-button @click="cancelMap">取消</a-button>-->
<!--        </a-row>-->
        <div class="bottom-control">
          <a-space>
            <a-button type="primary" @click="submitMap">确定</a-button>
            <a-button @click="cancelMap">取消</a-button>
          </a-space>
        </div>
      </a-col>
    </a-row>
  </div>
  </a-drawer>
</template>
<!--<script src="https://maps.googleapis.com/maps/api/js?&key=AIzaSyCybqDDmOePm8q3v2SvgYM5_0bErDAAk9Q"></script>-->
<script>
export default {
  name: "GoogleMapssss",
  data() {
    return {
      mapOptions: {
        language: 'en' // 设置地图语言为英文
      },
      center: { lat: 10.0, lng: 10.0 }, // 中心位置
      center_: { lat: 10.0, lng: 10.0 }, // 保存当前点位置
      currentPlace: null,
      markers: [],
      places: [],
      placeName: "",
      dialogVisible: true,
      googlemap: "",
      hasSetPin:false,
      // icon:require("@/assets/img/loading.gif"),
      zoom:1,
      open:false
    };
  },
  props:['gpsName'],
  mounted() {

  },
  methods: {
    openGoodleMaps() {
      console.log('2222')
      // if() {
      //
      // }
      if(this.gpsName!='Positioning'){
        let gpsArr = this.gpsName.split(',')
        if(gpsArr&&gpsArr.length>0){
          this.zoom = 10
          this.center.lat = gpsArr[0]*1
          this.center.lng = gpsArr[1]*1
          this.center_.lat = gpsArr[0]*1
          this.center_.lng = gpsArr[1]*1
          this.markers.push({ position: this.center_ });
        }
        this.hasSetPin = true
      } else {
        this.geolocate();
      }
      this.open = true;
    },
    onClose() {
      this.open = false;
    },
    handleClose() {},
    setPlace(place) {
      this.currentPlace = place;
      this.addMarkerFun();
    },
    addMarker() {
      this.addMarkerFun();
    },
    addMarkerFun(){
      if (this.currentPlace) {
        this.hasSetPin = true
        this.zoom = 10
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers = [];
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.center_ = marker;
        this.placeName = this.currentPlace.name;
        this.currentPlace = null;
      }
    },
    geolocate: function () {
      let vm = this

      if(navigator.geolocation){
        navigator.geolocation.getCurrentPosition((position) => {
          if(position && position.coords && position.coords.latitude){
            console.log(position.coords)
            // alert("获取地理位置："+position.coords.address)

            // alert(position.coords)
            vm.hasSetPin = true
            vm.zoom = 10
            vm.center = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            vm.center_ = vm.center
            vm.markers.push({ position: vm.center });
          }
        },(error)=>{  // html5 默认调用的谷歌的接口，会有安全限制
          console.log('222222222222')
          console.log(error.code)
          switch(error.code)
          {
            case error.PERMISSION_DENIED: // 许可拒绝,用户选了不允许
              // alert("您拒绝对获取地理位置的请求");
              // alert(error.message);
              break;
            case error.POSITION_UNAVAILABLE: // 连不上GPS卫星，或者网络断了
              // alert("位置信息是不可用的");
              // alert(error.message);
              break;
            case error.TIMEOUT:  // /超时了
              // alert("请求您的地理位置超时");
              // alert(error.message);
              break;
            case error.UNKNOWN_ERROR:
              // alert("未知错误");
              // alert(error.message);
              break;
          }
        });
      } else {
        // alert("navigator.geolocation未获取获取到地理位置");
        // vm.markers.push({ position: vm.center });
      }
    },
    clickMap(e) {
      console.log('-----------------');
      console.log(e);
      this.hasSetPin = true
      let longlat = e.latLng.lat() + "," + e.latLng.lng();
      this.center_ = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };
      this.markers = [];
      this.markers.push({ position: this.center_ });
    },
    clickMarker(val) {
      console.log('2222222222222222')
      console.log(val)
      this.center = val.position;
    },
    submitMap() {
      if(!this.hasSetPin){
        this.msgError(this.$t("googlemap.searchAndAdd"));
        return
      }
      let that = this;
      let obj = Object.assign({}, this.center_);
      obj.name = `${this.center_.lat},${this.center_.lng}`;
      var geocoder = new google.maps.Geocoder();
      console.log(geocoder);
      obj.city = '';
      geocoder.geocode({location:new google.maps.LatLng(this.center_.lat, this.center_.lng)},function geoResults(results, status){
        //这里处理结果和上面一模一样
        if (status == google.maps.GeocoderStatus.OK) {
          console.log(results[0])
          obj.address = results[0].formatted_address;
          results[0].address_components.forEach(j => {
            if(j.types.length >= 2) {
              if(j.types[0] == 'locality' && j.types[1] == 'political') {
                console.log(j.long_name)
                obj.city = j.long_name
              }
            }

          })
          that.open = false;
          that.$emit("setMap", obj);
          // alert('地理反解析结果：'+results[0].formatted_address);
          // alert('地理反解析结果：'+results[0].geometry.location);
        }else{
          alert("：error " + status);
        }
      });
      // return;

    },
    cancelMap() {
      this.$emit("closeMap");
    },
  },
};
</script>
<style scoped>
.map {
  /* height: 85vh; */
  /*padding-top: 10vh;*/
}
.add-btn {
  width: 60px;
  margin-left: 2%;
  height: 40px;
  background: #409eff;
  color: #fff;
  border: 0;
  border-radius: 5px;
}
.search-box input {
  height: 40px;
  width: 50%;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding-left: 7px;
  outline: none;
}
/* 底部两个按钮 */
.btn-box {
  margin-top: 2vh;
}
.btn-box .el-button {
  padding: 8px 30px;
  border-radius: 30px;
  width: 140px;
  height: 40px;
}
.confirm {
  color: #fff;
  background-color: #0778bc;
}
.cancel {
  color: #0778bc;
  border: 1px solid #0778bc;
}
.cursor {
  cursor: pointer;
}
@media only screen and (max-width: 820px) {
  .img-box .el-image {
    height: 4vh;
  }
  .search-box input {
    height: 5vh;
  }
  .add-btn {
    height: 5vh;
  }
}
.map .GmapMapBox{
  /*background-image: url(../assets/img/loading.gif);*/
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
</style>